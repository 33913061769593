import React, { forwardRef, useState } from "react";

export const WaitingListSection = forwardRef((props, ref) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isUsedEmail, setIsUsedEmail] = useState(false);
  const [signedUp, setSignedUp] = useState(false);

  const handleOpenPdf = (file) => {
    switch (file) {
      case "privacy":
        return window.open("/PrivacyPolicy.pdf", "_blank");
      default:
        return window.open("/Terms.pdf", "_blank");
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // const handleSignUp = async () => {
  //   if (validateEmail(email)) {
  //     try {
  //       const response = await fetch(
  //         "https://api.spade.fit/api/waiting-list-email",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ email }), // Sending the email in the request body
  //         }
  //       );

  //       const responseBody = await response.json();

  //       if (responseBody.statusCode === 200) {
  //         //console.log(responseBody);
  //         //console.log("Signing up with:", email);
  //         setIsValidEmail(true);
  //         setIsUsedEmail(false);
  //         setSignedUp(true); // Set signedUp to true indicating successful registration
  //       } else if (responseBody.statusCode === 409) {
  //         //console.log("Failed to sign up:", responseBody);
  //         setIsUsedEmail(true); // Handle case where email already exists
  //       } else {
  //         //console.log("Failed to sign up:", responseBody);
  //         setIsValidEmail(false);
  //         setIsUsedEmail(false);

  //         // Handle other failures
  //       }
  //     } catch (error) {
  //       //console.log("Error signing up:", error);
  //       setIsValidEmail(false);
  //       setIsUsedEmail(false);
  //       // Handle network or other unexpected errors
  //     }
  //   } else {
  //     //console.log("Invalid email address:", email);
  //     setIsValidEmail(false);
  //     setIsUsedEmail(false);
  //     // Handle invalid email format
  //   }
  // };

  const handleSignUp = async () => {
    if (validateEmail(email)) {
      try {
        const response = await fetch(
          "https://api.spade.fit/api/waiting-list-email-google-sheet",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }), // Sending the email in the request body
          }
        );

        const responseBody = await response.json();

        if (responseBody.statusCode === 200) {
          //console.log(responseBody);
          //console.log("Signing up with:", email);
          setIsValidEmail(true);
          setIsUsedEmail(false);
          setSignedUp(true); // Set signedUp to true indicating successful registration
        } else if (responseBody.statusCode === 409) {
          //console.log("Failed to sign up:", responseBody);
          setIsUsedEmail(true); // Handle case where email already exists
        } else {
          //console.log("Failed to sign up:", responseBody);
          setIsValidEmail(false);
          setIsUsedEmail(false);

          // Handle other failures
        }
      } catch (error) {
        //console.log("Error signing up:", error);
        setIsValidEmail(false);
        setIsUsedEmail(false);
        // Handle network or other unexpected errors
      }
    } else {
      //console.log("Invalid email address:", email);
      setIsValidEmail(false);
      setIsUsedEmail(false);
      // Handle invalid email format
    }
  };

  // const handleSignUp = async () => {
  //   setIsValidEmail(true);
  //   setSignedUp(true); // Set signedUp to true indicating successful registration
  // };
  if (signedUp) {
    return (
      <section
        ref={ref}
        className="questionSection text-center py-lg-5 d-flex justify-content-center flex-column align-items-center"
      >
        <h1 className="waitingListTitle ">JOIN THE COMMUNITY</h1>
        <div className="waitingListContainer border border-white  text-center d-flex justify-content-center flex-column align-items-center">
          <h3 className="waitingListSubtitle mb-4">
            Thank you for signing up, welcome to the club!
          </h3>
        </div>
      </section>
    );
  }

  return (
    <section
      ref={ref}
      className="questionSection py-lg-5 text-center d-flex justify-content-center flex-column align-items-center"
    >
      <div className="section text-center d-flex justify-content-center flex-column align-items-center w-100 h-100 bgOverlay">
        <h1 className="waitingListTitle  mb-5">JOIN THE COMMUNITY</h1>
        <div className="waitingListContainer border border-white  text-center d-flex justify-content-center flex-column align-items-center">
          <p>
            Enter your email to join our waiting list and receive exclusive
            early access, including three months of free personalised workout
            programmes!
          </p>
          {!isValidEmail && (
            <div className="text-danger">
              Please enter a valid email address.
            </div>
          )}
          {isUsedEmail && (
            <div className="text-danger">
              This email address is already in use.
            </div>
          )}
          <input
            type="text"
            placeholder="johndoe@spade.fit"
            className={`waitinglistInput   ${
              !isValidEmail || isUsedEmail ? "border-danger" : ""
            }`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <small>
            *By registering, you agree to the{" "}
            <button
              onClick={() => handleOpenPdf("terms")}
              className="text-warning bg-transparent p-0 border-0 outline-0 underline"
            >
              Terms of Use{" "}
            </button>{" "}
            and{" "}
            <button
              onClick={() => handleOpenPdf("privacy")}
              className="text-warning bg-transparent p-0 border-0 outline-0 underline"
            >
              Privacy Policy{" "}
            </button>{" "}
          </small>
          <button
            onClick={handleSignUp}
            className=" signUpBtn  px-5 m-4  fw-bold"
          >
            Sign me up
          </button>
        </div>
      </div>
    </section>
  );
});
